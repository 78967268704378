<template>
  <div className="tendency_lineChart" ref="echartsRef"></div>
</template>
<script setup>
import { ref, onMounted, nextTick, defineProps, watch } from "vue";
import * as echarts from "echarts/core";
import { LineChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import { TooltipComponent, GridComponent } from "echarts/components";

echarts.use([LineChart, CanvasRenderer, TooltipComponent, GridComponent]);

const props = defineProps({
  row: {
    type: Array,
    default: () => [],
  },
});
const echartsRef = ref(HTMLElement);

onMounted(() => {
  nextTick(() => {
    render();
  });
});

const render = () => {
  const myChart = echarts.init(echartsRef.value);
  myChart.setOption({
    tooltip: {
      trigger: "axis",
      textStyle: {
        align: "left",
      },
    },
    grid: {
      top: "4%",
      left: "4%",
      right: "4%",
      bottom: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      data: props.row.title,
    },
    yAxis: {
      type: "value",
    },
    series: [
      {
        name: "文字宣称",
        type: "line",
        data: props.row.effectiveness1,
      },
      props.row.effectiveness2.length <= 1
        ? {}
        : {
            name: "数字宣称",
            type: "line",
            data: props.row.effectiveness2,
          },
    ],
    color: ["#32a3be", "#e37893"],
  });
};

watch(
  () => props.row,
  () => {
    render();
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.tendency_lineChart {
  width: 400px;
  height: 200px;
  margin: 0 auto;
}
</style>
