<template>
  <div class="trend_tracking_detail">
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <h2 class="insight_title" style="margin-bottom: 10px">筛选</h2>
      <el-form
        ref="ruleForm"
        size="small"
        :model="form"
        :rules="rules"
        label-width="100px"
      >
        <el-row>
          <el-col class="screen_box">
            <el-form-item label="品类" prop="category" class="category">
              <el-select
                @change="productChange"
                v-model="form.category"
                placeholder="请选择品类"
              >
                <el-option
                  v-for="item in product"
                  :key="item"
                  :value="item"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item
              label="产品分类"
              prop="productType"
              class="classification"
            >
              <el-select
                v-model="form.productType"
                placeholder="请选择产品分类"
              >
                <el-option-group
                  v-for="group in classify"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
            <el-form-item label="宣称" prop="claim" class="classification">
              <el-select v-model="form.claim">
                <el-option-group
                  v-for="group in claimField"
                  :key="group.label"
                  :label="group.label"
                >
                  <el-option
                    v-for="item in group.options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-option-group>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
    <el-card style="margin-bottom: 20px; border-radius: 10px">
      <div class="trend_contrast">
        <div class="table_title">
          <h2 class="insight_title" style="margin-bottom: 10px">趋势对比</h2>
          <div class="reminder">
            <div class="literalness">
              <span></span>
              文字宣称
            </div>
            <div class="numerical">
              <span></span>
              数字宣称
            </div>
          </div>
        </div>
        <el-table
          class="table_box"
          style="width: 100%"
          v-loading="loading"
          :border="true"
          :data="tableData"
          :span-method="arraySpanMethod"
          :header-cell-style="headerCellStyle"
          :cell-style="cellStyle"
        >
          <el-table-column
            width="215"
            prop="claimLevel1"
            label="一级宣称"
            align="center"
          />
          <el-table-column
            width="190"
            prop="claimLevel2"
            label="二级宣称"
            align="center"
          />
          <el-table-column width="472" label="效果指数" align="center">
            <template #default="{ row }">
              <line-chart :row="row"></line-chart>
            </template>
          </el-table-column>
          <el-table-column
            width="472"
            prop="penetrationRate"
            label="提及率"
            align="center"
          >
            <template #default="{ row }">
              <bar-chart :row="row"></bar-chart>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-card>
  </div>
</template>
<script setup>
import { ref, watch } from "vue";
import { useRouter } from "vue-router";
import lineChart from "@/components/echarts/tendency/lineChart";
import barChart from "@/components/echarts/tendency/barChart";
import { filterDetail, filtrateParams } from "@/api/tendency";
const router = useRouter();
const routerQuery = ref({});
const tableData = ref([]);
const loading = ref(false);
const form = ref({});
const product = ref([]);
const classify = ref([]);
const claimField = ref([]);
let categoryProduct = null;
const notHaveNumeric = ref([]);

//首次进入页面
const afterEntry = () => {
  routerQuery.value = router.currentRoute.value.query;
  getParams();
};

const getParams = async () => {
  const {
    data: { categoryProductType, claim, notHaveNumericClaim },
  } = await filtrateParams();

  //没有数字宣称的部分
  notHaveNumeric.value = notHaveNumericClaim.map((item) => {
    const result = item.split("||");
    return {
      claimLevel1: result[0],
      claimLevel2: result[1],
    };
  });

  //品类
  categoryProduct = categoryProductType;
  product.value = Object.keys(categoryProductType);

  //宣称
  for (let key in claim) {
    claimField.value.push({
      label: key === "全部宣称" ? "" : key,
      options: claim[key].map((item) => {
        return {
          value: key + "||" + item,
          label: item,
        };
      }),
    });
  }
  await setClassifyResult(product.value[0]);
  await setInitialValue(claimField.value[0]);
};

//设置产品分类下拉内容
const setClassifyResult = (val) => {
  classify.value = [];
  const current = categoryProduct[val];
  for (let key in current) {
    classify.value.push({
      label: current[key].length > 1 ? key : "",
      options: current[key].map((item) => {
        return {
          value: key + "||" + item,
          label: item,
        };
      }),
    });
  }
};

const setInitialValue = (val) => {
  form.value = routerQuery.value;
  if (form.value.claim) {
    const result = form.value.claim.split("||");
    form.value.claim =
      result[0].split("\n")[0] + "||" + result[1].split("\n")[0];
  } else {
    form.value.claim = val.options[0].value;
  }
};
afterEntry();

//下拉改变
const formValueChange = async (params) => {
  loading.value = true;
  const { data } = await filterDetail(params);

  tableData.value = data.map((item) => {
    const claim1 = item.claimLevel1.substring(
      item.claimLevel1.lastIndexOf("||")
    );
    const claim2 = item.claimLevel2.substring(
      item.claimLevel2.lastIndexOf("||")
    );
    item.claimLevel1 = item.claimLevel1.replace(claim1, "");
    item.claimLevel2 = item.claimLevel2.replace(claim2, "");

    const title = [];
    for (let key in item.effectivenessNumericClaim) {
      title.push(key);
    }
    return {
      title: title,
      claimLevel1: item.claimLevel1,
      claimLevel2: item.claimLevel2,
      effectiveness1: withoutNumClaim(item, "effectivenessVerbalClaim", title),
      effectiveness2: withoutNumClaim(item, "effectivenessNumericClaim", title),
      penetrationRate1: withoutNumClaim(
        item,
        "penetrationRateVerbalClaim",
        title
      ),
      penetrationRate2: withoutNumClaim(
        item,
        "penetrationRateNumericClaim",
        title
      ),
    };
  });
  loading.value = false;
};

//处理没有数字宣称的数组项
const withoutNumClaim = (item, key, title) => {
  const claim1 = item.claimLevel1.split("||")[0];
  const claim2 = item.claimLevel2.split("||")[0];
  const result = notHaveNumeric.value.filter((el) => {
    return el.claimLevel1 === claim1 && el.claimLevel2 === claim2;
  });

  if (result.length == 1) {
    if (
      key !== "effectivenessNumericClaim" &&
      key !== "penetrationRateNumericClaim"
    ) {
      return [
        key.startsWith("effe")
          ? item[key][title[0]]
          : (item[key][title[0]] * 1000) / 10,
        key.startsWith("effe")
          ? item[key][title[1]]
          : (item[key][title[1]] * 1000) / 10,
      ];
    } else {
      return [
        key.startsWith("effe")
          ? item[key][title[0]]
          : (item[key][title[0]] * 1000) / 10,
      ];
    }
  } else {
    return [
      key.startsWith("effe")
        ? item[key][title[0]]
        : (item[key][title[0]] * 1000) / 10,
      key.startsWith("effe")
        ? item[key][title[1]]
        : (item[key][title[1]] * 1000) / 10,
    ];
  }
};

const arraySpanMethod = ({ row, rowIndex, columnIndex }) => {
  row.claimLevel1 = row.claimLevel1.replace("||", "\n");
  row.claimLevel2 = row.claimLevel2.replace("||", "\n");
  if (columnIndex === 0) {
    //过滤出一级宣称相同的数据
    const sameRoutIdList = tableData.value.filter(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    //找到一级宣称相同数据的第一个下标
    const firstIndex = tableData.value.findIndex(
      (i) => i.claimLevel1 === row.claimLevel1
    );
    //合并单元格
    if (sameRoutIdList.length > 1) {
      if (rowIndex === firstIndex) {
        return {
          rowspan: sameRoutIdList.length,
          colspan: 1,
        };
      } else {
        return {
          rowspan: 0,
          colspan: 0,
        };
      }
    } else {
      return {
        rowspan: 1,
        colspan: 1,
      };
    }
  }
};

const headerCellStyle = ({ rowIndex }) => {
  if (rowIndex === 0) {
    return "background-color:#C7D3E4;font-weight: 700;";
  }
};
const cellStyle = ({ rowIndex, columnIndex }) => {
  if (columnIndex == 1) {
    return "background-color:var(--el-background-color-base)";
  }
  if (columnIndex == 3) {
    return "border-right:0;";
  }
  if (rowIndex) {
    return "padding:4px 0";
  }
};

router.afterEach((to, form) => {
  console.log(form);
  if (to.name !== "TrendTracking") {
    sessionStorage.removeItem("TENDENCY");
  }
});

watch(
  () => form.value,
  (val) => {
    formValueChange(val);
    window.sessionStorage.setItem("TENDENCY", JSON.stringify(val));
  },
  { deep: true }
);
watch(
  () => form.value.claim,
  (val) => {
    let result = "";
    const variable = val.split("||");
    if (variable[0] !== "全部宣称") {
      result = variable[0] + "-" + variable[1];
    } else {
      result = "详情";
    }
    const element = document.querySelectorAll(".el-breadcrumb__inner")[2];
    element.innerText = result;
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.trend_tracking_detail {
  .insight_title {
    font-size: 20px;
  }

  .screen_box {
    display: flex;
    justify-content: flex-start;

    .category {
      width: 364px;
    }

    .classification {
      width: 390px;
    }
  }
}

.table_box {
  border: var(--el-table-border);
}

.trend_contrast {
  .table_title {
    display: flex;
    justify-content: space-between;

    .reminder {
      display: flex;
      justify-content: space-between;
      align-items: center;

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      span {
        margin-right: 6px;
        display: inline-block;
        width: 35px;
        height: 18px;
        border-radius: 4px;
      }

      .numerical {
        span {
          background-color: #e37893;
        }
      }

      .literalness {
        margin-right: 26px;

        span {
          background-color: #32a3be;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.trend_tracking_detail {
  .el-table th.el-table__cell > .cell {
    font-weight: 700;
  }

  .cell {
    white-space: pre-line !important;
  }
}
</style>
