<template>
  <div className="tendency_lineChart" ref="echartsRef"></div>
</template>
<script setup>
import { ref, onMounted, nextTick, defineProps, watch } from "vue";
import * as echarts from "echarts/core";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";

echarts.use([
  BarChart,
  CanvasRenderer,
  TooltipComponent,
  GridComponent,
  LegendComponent,
]);

const props = defineProps({
  row: {
    type: Array,
    default: () => [],
  },
});

const echartsRef = ref(HTMLElement);

onMounted(() => {
  nextTick(() => {
    render();
  });
});

const render = () => {
  const myChart = echarts.init(echartsRef.value);
  myChart.setOption({
    grid: {
      top: "4%",
      left: "4%",
      right: "4%",
      bottom: "4%",
      containLabel: true,
    },
    xAxis: {
      type: "category",
      axisTick: {
        show: false,
      },
      data: props.row.title,
    },
    yAxis: {
      type: "value",
      axisLabel: {
        formatter: "{value}%",
      },
      max: 100,
      min: 0,
    },
    series: [
      {
        data: props.row.penetrationRate1,
        barWidth: 60,
        type: "bar",
        label: {
          show: true,
          color: "#303133",
          formatter: (params) => {
            return params.value + "%";
          },
        },
      },
      props.row.penetrationRate2.length <= 1
        ? {}
        : {
            data: props.row.penetrationRate2,
            barWidth: 60,
            type: "bar",
            label: {
              show: true,
              color: "#303133",
              formatter: (params) => {
                return params.value + "%";
              },
            },
            barGap: "0",
          },
    ],
    color: ["#32a3be", "#e37893"],
  });
};

watch(
  () => props.row,
  () => {
    render();
  },
  { deep: true }
);
</script>
<style lang="scss" scoped>
.tendency_lineChart {
  width: 400px;
  height: 200px;
  margin: 0 auto;
}
</style>
