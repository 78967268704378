import request from "@/utils/axios";

export function filtrateParams() {
  return request.get("cosmetic/trend/tracking/param");
}

export function trackingFilter(params) {
  return request.get("cosmetic/trend/tracking/filter", params);
}

export function filterDetail(params) {
  return request.get("cosmetic/trend/tracking/filter/detail", params);
}
